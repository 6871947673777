import Route from './Route';

function App() {
  return (
    <>
      <Route />
    </>
  );
}

export default App;
